import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import bgImage from "../assets/bgImage.png";
import Blocked from "../components/Blocked";
import ParisLogo from "../assets/ParisLogo.png";
import Carousel from "react-material-ui-carousel";
import bullionStats from "../assets/bullioStats.png";
import { useEffect, useMemo, useState } from "react";
import MarketClose from "../components/MarketClose";
import EconomicNewsModal from "../components/EconomicNewsModal";
import { commodityCalculation } from "../components/constants";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem",
    boxSizing: "border-box",
  },
  topBar: {
    height: "28%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  logoBox: {
    width: "25%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: "80%",
    height: "70%",
    objectFit: "contain",
  },
  mainContainer: {
    width: "100%",
    height: "60%",
    display: "grid",
    gridTemplateColumns: "2.1fr 1fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  spotRateBox: {
    display: "flex",
    width: "33%",
    height: "89%",
    flexDirection: "column",
    gap: "0.5rem",
    color: "#FFFFFF",
    alignItems: "center",
  },
  liveRateValue: {
    borderRadius: "5px",
    boxSizing: "border-box",
    width: "100%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color .5s ease",
  },
  leftPart: {
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#FFFFFF",
    justifyContent: "space-between",
  },

  tabeHeader: {
    backgroundColor: "#595959",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
  },
  tableContent: {
    display: "flex",
    height: "84%",
    flexDirection: "column",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    boxSizing: "border-box",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "23%",
    background: "#FFFFFF",
    display: "flex",
    margin: ".5rem 0 0rem 0",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  timeArea: {
    height: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#F5F5F5",
  },

  banner: {
    height: "45%",
    border: "1px solid #89898B",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  highLowBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  ratio: {
    height: "100%",
    width: "100%",
    gridArea: "stat",
    backgroundColor: "#FFFFFF",

    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: " 1px solid #FFFFFF33",
  },
  bullionStatsImg: {
    width: "12vw",
    height: "auto",
    color: "black",
  },
  updates: {
    boxSizing: "border-box",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "8%",
    background: "red",
    color: "#FFFFFF",
  },
  updatesHeader: {
    background: "#595959",
    minWidth: "250px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#FFFFFF",
    width: "100%",
    minHeight: "100%",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const buyersColor = "#2B8BE4";
  const sellersColor = "#DE5B56";

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <Box
      style={{
        backgroundImage: `url(${bgImage})`,
        width: "100dvw",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openSubscriptionExpierd || openBlocked) && classes.blur}
    >
      <Box className={classes.mainBody}>
        {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
        <Box className={classes.topBar}>
          <Box className={classes.logoBox}>
            <img className={classes.logoImage} src={ParisLogo} alt="leenLogo" />
          </Box>
          <Box className={classes.spotRateBox}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    background: "#FFFFFF",
                    color: "#2C2B29",
                    borderRadius: "4px",
                    padding: "0rem .6rem 0rem .6rem",
                    fontWeight: 600,
                  }}
                >
                  $
                </Typography>

                <Typography style={{ fontSize: "1.7vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.bidOrBuy?.toUpperCase()} Oz
                </Typography>
              </Box>
              <Box className={classes.highLowBox}>
                <TbTriangleInvertedFilled size={26} color="#D43C3C" />
                <Typography style={{ fontSize: "1.6vw", fontFamily: "Oswald" }}>
                  {(!gold?.cur?.lowPrice
                    ? 0.0
                    : Number(spread.goldLowSpread) + Number(gold?.cur?.lowPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.liveRateValue}
              sx={{
                backgroundColor:
                  Number(gold?.cur?.bid).toFixed(2) <
                  Number(gold?.pre?.bid).toFixed(2)
                    ? "#CE3C31"
                    : Number(gold?.cur?.bid).toFixed(2) >
                      Number(gold?.pre?.bid).toFixed(2)
                    ? "#CE3C31"
                    : "",
                border:
                  Number(gold?.cur?.bid).toFixed(2) <
                  Number(gold?.pre?.bid).toFixed(2)
                    ? "1px solid #CE3C31"
                    : Number(gold?.cur?.bid).toFixed(2) >
                      Number(gold?.pre?.bid).toFixed(2)
                    ? "1px solid #CE3C31"
                    : "1px solid #FFFFFF",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {(!gold?.cur?.bid
                  ? 0.0
                  : Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                )
                  .toFixed(2)
                  .split("")
                  .map((letter, index) => (
                    <Typography
                      key={index}
                      style={{
                        width: letter == "." ? "" : "4.3vw",
                        fontSize: "8.2vw",
                        fontWeight: 900,
                        fontFamily: "Oswald",
                      }}
                    >
                      {letter}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box className={classes.spotRateBox}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    background: "#FFFFFF",
                    color: "#2C2B29",
                    borderRadius: "4px",
                    padding: "0rem .6rem 0rem .6rem",
                    fontWeight: 600,
                  }}
                >
                  $
                </Typography>
                <Typography style={{ fontSize: "1.7vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.askOrSell?.toUpperCase()} Oz
                </Typography>
              </Box>
              <Box className={classes.highLowBox}>
                <TbTriangleFilled size={26} color="#3993D4" />
                <Typography style={{ fontSize: "1.5vw", fontFamily: "Oswald" }}>
                  {(!gold?.cur?.highPrice
                    ? 0.0
                    : Number(spread.goldHighSpread) +
                      Number(gold?.cur?.highPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box
              className={classes.liveRateValue}
              sx={{
                backgroundColor:
                  Number(gold?.cur?.ask).toFixed(2) <
                  Number(gold?.pre?.ask).toFixed(2)
                    ? "#CE3C31"
                    : Number(gold?.cur?.ask).toFixed(2) >
                      Number(gold?.pre?.ask).toFixed(2)
                    ? "#2B8BE4"
                    : "",
                border:
                  Number(gold?.cur?.ask).toFixed(2) <
                  Number(gold?.pre?.ask).toFixed(2)
                    ? "1px solid #CE3C31"
                    : Number(gold?.cur?.ask).toFixed(2) >
                      Number(gold?.pre?.ask).toFixed(2)
                    ? "1px solid #2B8BE4"
                    : "1px solid #FFFFFF",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {(!gold?.cur?.ask
                  ? 0.0
                  : Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                )
                  .toFixed(2)
                  .split("")
                  .map((letter, index) => (
                    <Typography
                      key={index}
                      style={{
                        width: letter == "." ? "" : "4.3vw",
                        fontSize: "8.2vw",
                        fontWeight: 900,
                        fontFamily: "Oswald",
                      }}
                    >
                      {letter}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.mainContainer}>
          <Box className={classes.leftPart}>
            <Box className={classes.tabeHeader}>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  pl: "15px",
                  fontSize: "2.6vw",
                  fontWeight: "bold",
                }}
              >
                COMMODITY
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "2.6vw",
                  fontWeight: "bold",
                }}
              >
                WEIGHT
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2.6vw",
                  fontWeight: "bold",
                }}
              >
                SELL
                <Typography sx={{ fontSize: "1.5vw", mt: 2.2 }}>
                  &nbsp;AED
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.tableContent}>
              {commodities?.map((commodity, idx) => {
                const words = commodity.display_metal.split(" ");

                return (
                  <Box key={idx} className={classes.tableRow1}>
                    <Typography
                      sx={{
                        // fontSize: "1.4vw",
                        fontSize: "4vw",
                        fontWeight: 900,
                        justifyContent: "left",
                        width: "3.2vw",
                      }}
                      className={classes.tableRowColumn}
                    >
                      {/* {commodity?.display_metal.toUpperCase()} */}
                      <span
                        style={{
                          fontSize: "4.9vw",
                          fontWeight: 900,
                          paddingLeft: "15px",
                        }}
                      >
                        {words[0]?.toUpperCase()}&nbsp;
                        {words[1]?.toUpperCase()}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          lineHeight: "1.6vw",
                          justifyContent: "center",
                        }}
                      >
                        {words?.map((word, index) => {
                          return index > 1 ? (
                            <span
                              key={index}
                              style={{
                                fontSize: "1.7vw",
                                fontWeight: "bold",
                              }}
                            >
                              {word.toUpperCase()}
                            </span>
                          ) : (
                            ""
                          );
                        })}

                        <span
                          style={{
                            fontSize: "1.9vw",
                            fontWeight: "bold",
                          }}
                        >
                          {commodity.unitLabel === "TTB" ? (
                            ""
                          ) : (
                            <>&nbsp;{commodity.displayPurity}</>
                          )}
                        </span>
                      </span>
                    </Typography>
                    <Box
                      className={classes.tableRowColumn}
                      sx={{ justifyContent: "left" }}
                    >
                      <Typography
                        style={{ fontSize: "4.9vw", fontWeight: 900 }}
                        sx={{
                          paddingLeft: { lg: "6vw", md: "4vw" },
                          fontFamily: "Oswald",
                        }}
                      >
                        {commodity.unit} {commodity.unitLabel}
                      </Typography>
                    </Box>
                    <Box
                      className={classes.tableRowColumn}
                      sx={{
                        justifyContent: "left",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "4.9vw",
                          fontWeight: 900,
                          fontFamily: "Oswald",
                        }}
                        sx={{
                          paddingLeft: { lg: "4.6vw", md: "4.5vw" },
                        }}
                      >
                        {!gold?.cur?.ask
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur?.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )
                              .split("")
                              .map((letter, index) => (
                                <span
                                  key={index}
                                  style={{ width: letter !== "." && "14px" }}
                                >
                                  {letter}
                                </span>
                              ))}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.timeArea}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.7vw",
                  letterSpacing: "1px",
                }}
              >
                {formatTime()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  mt: -2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2.2vw",
                    letterSpacing: "1px",
                  }}
                >
                  {formatday()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontSize: "2.2vw",
                  }}
                >
                  {formatDate()
                    .toUpperCase()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.banner}>
              <Carousel
                animation="fade"
                navButtonsAlwaysVisible={false}
                interval={10000}
                indicatorContainerProps={{ style: { display: "none" } }}
                sx={{
                  height: "100%",
                  borderRadius: "2px",
                }}
                transitionTime={3000}
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "26vh",
                      objectFit: "cover",
                    }}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>
            <Box
              sx={{
                height: "23%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className={classes.ratio}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    BUYERS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: "1vw",
                      fontWeight: "bold",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2B8BE4"
                          : "#DE5B56",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    SELLERS
                  </Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color: "#2B8BE4",
                      fontWeight: "bold",
                    }}
                  >
                    {ratio?.Buyers}
                  </Typography>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImg}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      fontWeight: "bold",
                      color: "#DE5B56",
                    }}
                  >
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              color: "#FFFFFF",
            }}
          >
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                PARIS UPDATES
              </Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <marquee
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", color: "#000000" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.5vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </Box>
  );
};

export default Home;
